.body {
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
  background-color: #000; /* Set background color of the body to black */
}
.container {
  padding: 10rem 6rem;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}
.wrapper {
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
}
.wrapper > img {
  margin-top: 12rem; /* Add margin-top here */
}

.blueCircle {
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  z-index: -99;
  position: absolute;
}

.wrapper > img {
  width: 30rem;
  position: absolute;
}

.cart2 {
  position: absolute;
  bottom: 25%;
  right: 5%;
  display: flex;
  gap: 1rem;
  margin-top: 2rem; /* Add margin-top here */

  align-items: center;
}
.cart2 > svg {
  width: 30px;
  height: 30px;
  background: white;
  padding: 10px;
  border-radius: 50%;
  border: 6px solid var(--black);
}
.signup {
  display: flex;
  gap: 1rem;
  align-items: center;
  background: black;
  padding: 10px;
  color: white;
  font-size: 0.8rem;
  border-radius: 15px;
  box-shadow: var(--shadow1);
}

.signup > :first-child {
  display: block;
  width: 6rem;
}
.signup > :nth-child(2) {
  border-radius: 50%;
  border: 1px solid skyblue;
  display: flex;
  width: 20px;
  height: 20px;
  padding: 5px;
  align-items: center;
  justify-content: center;
}

.h_sides {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.text1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  color: white;
  width: 300px;
  display: block;
  font-weight: 600;
}
.text2 {
  display: flex;
  color: white;
  flex-direction: column;
  width: 150px;
}
.text2 > :first-child {
  font-weight: 600;
  font-size: 1.6rem;
}
.text2 > :nth-child(2) {
  display: block;
}
.traffic {
  display: flex;
  color: white;

  flex-direction: column;
  text-align: right;
}
.traffic > :first-child {
  font-weight: 800;
  color: white;

  font-size: 2.5rem;
}
.customers {
  display: flex;
  flex-direction: column;
  text-align: right;
  color: white;
}
.customers > :first-child {
  font-weight: 800;
  font-size: 2.5rem;
}
@media screen and (max-width: 856px) {
  .container {
    padding: 0rem 1rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }
  .text1,
  .text2 > span:nth-of-type(2) {
    font-size: 0.8rem;
    text-align: justify;
  }
  .text2 {
    margin-top: 3rem;
  }
  .text2 > span:nth-of-type(1),
  .traffic > span:nth-child(1),
  .customers > span:nth-child(1) {
    font-size: 0.7rem;
    text-align: left;
  }
  .blueCircle {
    position: absolute;
    width: 40%;
    height: 50%;
  }

  .wrapper > img {
    width: 30%;
    height: 30%;
    right: 0;
    left: 30;
    margin-top: -2rem;
  }
  .container {
    grid-template-areas:
      "left center center"
      "right right right";
  }
  .container > :first-child {
    grid-area: left;
    grid-template-rows: none;
    gap: 8rem;
  }

  .container > :nth-child(3) {
    grid-area: right;
    display: flex;
    justify-content: space-around;
    margin: 2rem 0rem;
  }
}
@media screen and (max-width: 640px) {
  .blueCircle {
    top: 0;
    width: 16rem;
    height: 72%;
  }
  .wrapper > img {
    top: 3rem;
    width: 12rem;
    height: 15rem;
  }
  .cart2 {
    transform: scale(0.7);
    right: -2rem;
    bottom: 0;
  }
}

.slidercontainer {
  position: relative;
  padding: 3rem;
}
.slidercontainer .swiper {
  width: 70%;
  height: 12rem;
  position: unset;
}
.slidercontainer .swiper-slide {
  background: white;
  display: flex;
  border-radius: 10px;
  padding: 1rem;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  position: relative;
}
.slidercontainer .swiper-button-prev {
  left: 6rem;
}
.slidercontainer .swiper-button-next {
  right: 6rem;
}
.slidercontainer .swiper-button-next,
.slidercontainer .swiper-button-prev {
  color: white;
}
.left-s {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.name {
  display: flex;
  flex-direction: column;
  color: black;
}
.name > :nth-child(1) {
  font-size: 1.4rem;
  font-weight: 600;
  color: black;
}
.name > :nth-child(2) {
  font-size: 0.7rem;
  color: black;
}
.left-s > :nth-child(2) {
  font-weight: bold;
  font-size: 2rem;
  color: black;
}
.left-s > :nth-child(3) {
  font-size: 0.6rem;
  border: 1px solid black;
  padding: 5px 10px;
  color: black;
  width: max-content;
  border-radius: 15px;
}
.img-p {
  /* transform: rotate(-20deg); */
  position: absolute;
  right: 0;
  width: 40%;
  height: 80%;
  bottom: -2%;
}
@media screen and (max-width: 856px) {
  .slidercontainer .swiper {
    width: 90%;
    height: 10rem;
  }
  .slidercontainer .swiper-button-next,
  .slidercontainer .swiper-button-prev {
    left: 21.4rem;
  }
  .slidercontainer.swiper-button-next,
  .slidercontainer .swiper-button-prev {
    left: 1rem;
  }
}

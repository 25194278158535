.body {
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.box {
  padding: 0 0rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding-bottom: 600px; /* Ensure a gap between products and footer */
  color: white;
  min-height: calc(100vh - 100px); /* Adjust as per your footer height */
}

.box > :nth-child(1) {
  position: absolute;
  width: 8rem;
  left: 30%;
  top: -3rem;
}

.products {
  display: grid;
  width: 90%;
  grid-template-columns: 25% auto;
}

.menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-weight: 500;
  font-size: 1.3rem;
  color: white;
}

.menu > li:hover {
  color: var(--pink);
  cursor: pointer;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  height: 25rem;
  grid-gap: 2rem;
  justify-content: space-between;
}

.product {
  width: 13rem;
  height: 9rem;
  background-color: white;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  border-radius: 1rem;
}

.product > img {
  width: 5rem;
  height: 5rem;
}
.footer {
  margin-top: auto; /* Push the footer to the bottom */
}

@media screen and (max-width: 856px) {
  .box {
  }
  .box > :nth-child(1) {
    display: none;
  }
  .products {
    grid-template-columns: none;
    gap: 1rem;
    padding-bottom: 160vh; /* Adjust as necessary for proper spacing */
  }
  .menu {
    flex-direction: row;
  }
}

@media screen and (max-width: 640px) {
  .menu {
    margin-left: -2rem;
    gap: 0.5rem;
    flex-wrap: wrap;
    width: 100%;
    font-size: 1.2rem;
  }
  .box {
    padding-bottom: 200vh; /* Adjust as necessary for proper spacing */
  }
  .box h1 {
    text-align: center;
    font-size: 1rem;
  }
}

/* Base styles */
.container {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000;
  color: white;
}
.cartBadge {
  position: absolute;
  top: 30px;
  background-color: #fe956f;
  color: white;
  font-size: 9px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 50%;
}

.logo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo > img {
  width: 2.5rem;
  height: 2.5rem;
}

.logo > span {
  font-weight: 600;
}

.right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.menu {
  display: flex;
  gap: 1rem;
  font-weight: 500;
  list-style: none;
}

.menu > li:hover {
  color: #fe956f;
  cursor: pointer;
}

.search {
  width: 10rem; /* Increased width for better input on smaller screens */
  height: 2rem; /* Adjusted height for better visibility */
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
}

.cart {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.bars {
  display: none;
}

/* Media queries */
@media screen and (max-width: 856px) {
  .right {
    display: none; /* Hide the right-side menu on medium screens */
  }
}

@media screen and (max-width: 640px) {
  .container {
    flex-direction: column;
    align-items: center; /* Center align items on smaller screens */
    padding: 1rem; /* Reduced padding for better space utilization */
  }

  .menu {
    flex-direction: column;
    display: none; /* Hide the menu items */
    margin: 1rem 0; /* Add margin for better spacing */
  }

  .bars {
    display: block;
    cursor: pointer;
  }

  .search {
    display: none; /* Hide the search input on smaller screens */
  }

  .right {
    display: flex; /* Display the right-side menu on smaller screens */
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    color: white;
    border-radius: 5px;
    padding: 1rem;
    position: absolute;
    top: 3rem;
    right: 1.5rem;
    z-index: 9999;
  }
}

.cart {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 1.5rem;
  margin-top: 0.5rem;
}

.cart-list {
  display: flex;
  flex-direction: column;
  flex: 3 0;
  justify-content: space-evenly;
  /* border: 1px solid #ffc107; */
  border-radius: 1rem;
  list-style-type: none;
  margin: 0.5rem;
  padding: 0;
  color: white;
}

.cart-list li {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  /* border-bottom: 1px solid #ffc107; */
  color: white;
}

.cart-list li:last-child {
  border: none;
}

.cart-list li img {
  width: 5rem;
  height: 5rem;
  border-radius: 100rem;
  object-fit: cover;
}

.cart-list li div {
  padding: 1rem;
}

.cart-list li div:not(:first-child) {
  flex-basis: 18%;
}

.cart-list li select {
  width: 3rem;
  outline: none;
  border: none;
  border-bottom: 1px solid lightgrey;
  font-size: 1.1rem;
  font-weight: 100;
}

.cart-list .remove_button {
  border-radius: 1rem;
  border: none;
  padding: 0.5rem;
  color: #e72929;
  opacity: 0.7;
  outline: none;
}

.cart-list .remove_button:hover {
  opacity: 1;
  cursor: pointer;
}

.checkout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 3;
  height: 20rem;
  border: 1px solid white;
  border-radius: 1rem;
  padding: 0.5rem;
  margin: 0.5rem;
}

.checkout > div {
  font-size: 1.4rem;
  margin: 1rem;
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.checkout .foods_count {
  margin-bottom: 1.5rem;
}

.checkout .foods_count::before {
  content: "Total Amount: ";
  color: grey;
}

.checkout .total_price::before {
  content: "Tax (10%):";
  color: grey;
}
.checkout .subtotal::before {
  content: "SubTotal:";
  color: grey;
}

.checkout a {
  padding: 1rem;
  color: black;
  background: white;
  display: block;
  width: 99%;
  border-radius: 1rem;
  text-align: center;
  justify-self: center;
}

.checkout a:hover {
  opacity: 0.8;
  cursor: pointer;
}

/* Quantity controls */
.quantity-controls {
  display: flex;
  align-items: center; /* Center items vertically */
}

/* Style for the plus and minus buttons */
.quantity-controls button {
  background-color: gold;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  margin-right: 5px; /* Add spacing between the buttons */
}

/* Style for the plus button */
.quantity-controls button:last-child {
  margin-right: 0;
}

/* Hover effect for buttons */
.quantity-controls button:hover {
  background-color: #443300;
  transform: translateY(-5px);
}

/* Disabled state for buttons */
.quantity-controls button:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

/* Adjust the size and alignment of the quantity span */
.quantity-controls span {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px; /* Add spacing between the quantity and buttons */
  display: flex;
  align-items: center; /* Center the content vertically */
}

/* Adjust the size of the SVG icons */
.quantity-controls svg {
  width: 18px; /* Set the width of the SVG icons */
  height: 18px;
  display: flex;
  margin-left: 7px;
  align-items: center;
  /* Center the content vertically */
  /* Set the height of the SVG icons */
}
/* Hover effect for SVG icons */
.quantity-controls:hover svg {
  fill: #fff; /* Change the fill color to white */
}

.summary {
  width: 30%;
  padding: 16px;
  color: #fff; /* Change the font color to white */
}

/* Style for the Invoice modal content */
.ant-modal-content {
  background-color: #f0f0f0; /* Set background color */
}

/* Style for the Invoice modal title */
.ant-modal-title {
  color: #443300; /* Set title color */
}

/* Style for the Invoice form */
.invoice-form {
  padding: 20px;
}

/* Style for form labels */
.ant-form-item-label {
  color: #443300; /* Set label color */
}

/* Style for form inputs */

/* Style for Select component */
.ant-select {
  width: 100%;
}

/* Style for Select options */

/* Style for primary button */
.ant-btn-primary {
  background-color: #443300; /* Set button background color */
  border-color: #443300; /* Set button border color */
}

/* Style for primary button on hover */
.ant-btn-primary:hover {
  background-color: #221100; /* Set button background color on hover */
  border-color: #221100; /* Set button border color on hover */
}
/* Default styles */

@media screen and (max-width: 768px) {
  .cart {
    flex-direction: column;
    align-items: stretch;
    margin: 0.5rem;
  }

  .cart-list {
    flex: 1 0;
    margin: 0.5rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .cart-list li {
    flex-basis: 45%; /* Adjust as per your design */
    margin: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .quantity-controls {
    display: flex;
    align-items: center;
  }

  .quantity-controls button {
    margin-right: 5px;
  }

  .checkout {
    flex: 1 0;
    height: auto;
    margin: 0.5rem 0;
  }

  .summary {
    width: 100%; /* Adjust to fill the container */
    padding: 16px;
    color: #fff; /* Change the font color to white */
    margin-top: 1rem; /* Adjust spacing */
  }

  .ant-modal-content {
    background-color: #fff;
  }

  .ant-modal-title {
    color: #443300;
  }

  .invoice-form {
    padding: 10px;
  }
}

/* SuccessPage.css */
body {
  background-color: #000000; /* Black background color */
  color: #ffffff; /* White text color */
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
}
/* SuccessPage.css */

.success-page {
  text-align: center;
  margin-top: 50px;
  background-color: #000000; /* Black background color */
  color: #ffffff; /* White text color */
  padding: 20px;
}

.success-page h1 {
  font-size: 36px;
  color: #00ff00; /* Green color for success message */
}

.success-page p {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 600px) {
  .success-page {
    padding: 10px;
    margin-top: 20px;
  }

  .success-page h1 {
    font-size: 24px;
  }

  .success-page p {
    font-size: 16px;
  }
}
